<template>
  <div>
    <section class="container mb-4 sm:mb-0">
      <div
        class="
          container__inner
          flex
          py-6
          md:py-12
          md:flex-row
          flex-col
          items-center
          md:items-start
          justify-between
        "
      >
        <div
          class="lg:flex-grow md:w-6/12 lg:pr-24 flex flex-col md:items-start"
        >
          <h1 class="text-royalblue-900 mb-8">
            Specialist Neurological Physiotherapy &amp; Rehabilitation Services
          </h1>
          <p class="h5 text-royalblue-400 mb-8 leading-relaxed">
            We are proud to offer specialist neurological physiotherapy.
          </p>
          <p class="mb-8 leading-relaxed">
            We are passionate about delivering a service which is both caring
            and highly professional, keeping your goals at the centre of our
            treatments.
          </p>
        </div>
        <div class="hidden md:block md:w-5/12 lg:w-5/12 w-1/2">
          <img
            class="object-cover object-center rounded"
            alt="hero"
            src="https://images.unsplash.com/photo-1494386346843-e12284507169?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80"
          />
        </div>
      </div>
    </section>
    <section class="container flex flex-col mb-14 sm:mb-20">
      <div class="service-block">
        <div class="container__inner relative z-10">
          <div class="flex flex-wrap justify-center -m-4">
            <div class="p-4 sm:w-1/3 sm:mb-0 mb-4">
              <div class="rounded overflow-hidden mb-8">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src="../assets/images/services.jpg"
                />
              </div>
              <base-btn url="Services">Our Services</base-btn>
            </div>
            <div class="p-4 sm:w-1/3 sm:mb-0 mb-4">
              <div class="rounded overflow-hidden mb-8">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src="../assets/images/treatments.jpg"
                />
              </div>
              <base-btn url="Treatments">Treatments</base-btn>
            </div>
            <div class="p-4 sm:w-1/3 sm:mb-0 mb-4">
              <div class="rounded overflow-hidden mb-8">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src="../assets/images/about-us-new.jpg"
                />
              </div>
              <base-btn url="About">About us</base-btn>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container mb-14 sm:mb-20">
      <div class="container__inner">
        <h2 class="mb-5">Who can we help?</h2>
        <p class="h5 text-royalblue-400 mb-8 leading-relaxed">
          We treat any adult with a neurological condition, for example:
        </p>
        <div class="flex flex-wrap -m-4">
          <div class="w-1/2 md:w-1/3 md:mb-0 p-4">
            <ul>
              <li v-for="(condition, index) in conditionsList1" :key="index">
                {{ condition }}
              </li>
            </ul>
          </div>
          <div class="w-1/2 md:w-1/3 md:mb-0 p-4">
            <ul>
              <li v-for="(condition, index) in conditionsList2" :key="index">
                {{ condition }}
              </li>
            </ul>
          </div>
          <div class="w-full md:w-1/3 md:mb-0 p-4">
            <p>
              As well as neurological conditions we can also treat the
              challenges which arise from longer term joint and mobility
              problems which may be affecting balance, gait or a sporting
              activity.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="flex flex-col mb-14 sm:mb-20 mx-auto xl:px-12">
      <div
        class="
          px-5
          md:px-6
          lg:px-12
          bg-royalblue-200 bg-bubbles-bg bg-right-top bg-no-repeat
          xl:rounded
          py-12
        "
      >
        <div class="container__inner text-white">
          <h2 class="mb-8">What our patients say</h2>
          <div class="flex flex-wrap -m-4">
            <div class="w-full md:w-1/3 md:mb-0 p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="block w-5 h-5 text-gray-400 mb-4"
                viewBox="0 0 975.036 975.036"
              >
                <path
                  d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
                ></path>
              </svg>
              <p>
                “We were delighted to find 'Heather Ross' who agreed to visit me
                at home and assessed me. Soon after Heather started it became
                clear to us that she had the knowledge and professional skills
                to help me recover. Since then Heather has visited me twice a
                week and helped me to make significant progress...”
                <router-link
                  class="no-formatting font-bold text-white"
                  :to="{ name: 'Testimonials' }"
                  >Read more</router-link
                >
              </p>
            </div>
            <div class="w-full md:w-1/3 md:mb-0 p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="block w-5 h-5 text-gray-400 mb-4"
                viewBox="0 0 975.036 975.036"
              >
                <path
                  d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
                ></path>
              </svg>
              <p>
                “Gemma is a highly qualified and experienced
                neuro-physiotherapist with an astonishing range of skills. She
                has cared for my wife Margaret since her very severe stroke
                nearly three years ago with great tenderness and sensitivity to
                her condition. Gemma is particular rly skilled in managing the
                relationship...”
                <router-link
                  class="no-formatting font-bold text-white"
                  :to="{ name: 'Testimonials' }"
                  >Read more</router-link
                >
              </p>
            </div>
            <div class="w-full md:w-1/3 md:mb-0 p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="block w-5 h-5 text-gray-400 mb-4"
                viewBox="0 0 975.036 975.036"
              >
                <path
                  d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
                ></path>
              </svg>
              <p>
                “My neurophysiotherapy treatment has been life changing.I
                understand so much more about what I can be doing to correct my
                positioning, what is going on with my muscles and connective
                tissues and my pelvis. Also why things are not right about my
                posture. It's an ongoing learning experience and the knowledge
                of Diane...
                <router-link
                  class="no-formatting font-bold text-white"
                  :to="{ name: 'Testimonials' }"
                  >Read more</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="container__inner">
        <div class="flex flex-wrap -m-4">
          <div class="flex flex-col w-full md:w-1/2 p-4">
            <h3 class="mb-5">Areas Covered</h3>
            <div class="flex -m-4">
              <div class="w-full md:w-1/2 md:mb-0 p-4">
                <ul>
                  <li v-for="(area, index) in areasList1" :key="index">
                    {{ area }}
                  </li>
                </ul>
              </div>
              <div class="w-full md:w-1/2 md:mb-0 p-4">
                <ul>
                  <li v-for="(area, index) in areasList2" :key="index">
                    {{ area }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full md:w-1/2 p-4">
            <h3 class="mb-5">Community Setting</h3>
            <p>
              We have chosen to treat people in their own home or in a community
              setting. Evidence shows that this is a very effective way to treat
              and rehabilitate neurological conditions and can enhance progress.
              We shall tailor our treatment to focus on agreed goals. We also
              have physiotherapy/ rehabilitation assistants who will be working
              alongside the physiotherapists to practise and repeat activity and
              exercise programmes. We work closely with a personal trainer who
              will assist in improving your fitness and condition. Your program
              will be regularly reviewed and progressed as required. This might
              help to increase frequency of treatments while keeping your costs
              under control.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import BaseBtn from "../components/ui/BaseBtn.vue";

export default {
  name: "Home",
  title: "Home page",
  components: {
    "base-btn": BaseBtn,
  },
  data() {
    return {
      conditionsList1: [
        "Stroke",
        "Multiple Sclerosis (MS)",
        "Guillain Barre",
        "Parkinsons Disease",
      ],
      conditionsList2: [
        "Cerebral Palsy",
        "Brain injury",
        "Ataxia",
        "Vestibular problems",
      ],
      areasList1: [
        "Chandlers Ford",
        "Clanfield",
        "Cowplain",
        "Denmead",
        "Eastern Southampton",
        "Emsworth",
        "Fareham",
        "Gosport",
        "Hamble",
        "Hayling Island",
      ],
      areasList2: [
        "Hedge End and Bishops Waltham",
        "Portsmouth",
        "Sarisbury Green and Locksheath",
        "Soberton",
        "Ringwood",
        "Warsash",
        "Waterlooville",
        "Wickham",
        "Whiteley",
      ],
    };
  },
};
</script>
